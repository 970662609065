<template>
  <div class="body">11</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.body {
  max-width: 750px;
  margin: 0 auto;
}
</style>
